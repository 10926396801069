<template>
  <div class="app-header">
    <nav class="navbar navbar-light navbar-expand-lg">
      <div class="container-fluid d-flex align-items-center">
        <div class="d-flex align-items-center flex-nowrap">
          <!-- Sidebar Toggle -->
          <div class="nav-item">
            <a
              class="nav-link hide-sidebar-toggle-button d-flex align-items-center"
              href="#"
              @click.prevent="toggleSidebar"
            >
              <i class="material-icons">menu</i>
            </a>
          </div>
          <!-- Back Button -->
          <div class="nav-item">
            <a
              class="nav-link d-flex align-items-center"
              @click="goBack()"
              href="#"
            >
              <i class="material-icons" title="Back">arrow_back</i>
            </a>
          </div>
          <!-- Forward Button -->
          <div class="nav-item">
            <a
              class="nav-link d-flex align-items-center"
              @click="goForward()"
              href="#"
            >
              <i class="material-icons" title="Forward">arrow_forward</i>
            </a>
          </div>
        </div>

        <!-- Support/Contact Information -->
        <div
          class="d-flex align-items-center justify-content-center flex-grow-1"
        >
          <p class="d-none d-lg-block mb-0" style="color: #9a9cab">
            <strong>Technical Support: </strong>
            <a href="mailto:support@varstechnology.co.uk"
              >support@varstechnology.co.uk</a
            >
            or call <a href="tel:08081751100"> 0808 175 1100</a> |
            <strong>Claim Enquiries: </strong>
            <a href="mailto:enquiries@varsanpr.co.uk"
              >enquiries@varsanpr.co.uk</a
            >
          </p>
        </div>

        <!-- Logout, Dark/Light Mode, Language -->
        <div class="d-flex align-items-center ms-auto flex-nowrap">
          <a
            class="nav-link d-flex align-items-center"
            href="javascript:void(0)"
            @click="handleLogout"
          >
            <i class="material-icons" title="Logout">logout</i>
          </a>
          <button
            v-if="!night"
            class="btn btn-sm d-flex align-items-center"
            style="color: black"
            @click="$store.dispatch('auth/changeMode', !night)"
          >
            <span class="material-icons" title="Dark Mode">dark_mode</span>
          </button>
          <button
            v-else
            class="btn btn-sm d-flex align-items-center"
            style="color: white"
            @click="$store.dispatch('auth/changeMode', !night)"
          >
            <span class="material-icons" title="Light Mode">light_mode</span>
          </button>

          <a
            class="nav-link language-dropdown-toggle d-flex align-items-center mx-2"
            href="javascript:void(0);"
            id="languageDropDown"
            data-bs-toggle="dropdown"
          >
            <img :src="`/images/flags/${$i18n.locale}.png`" alt="" />
          </a>
          <ul
            class="dropdown-menu dropdown-menu-end language-dropdown"
            id="languageDropdown"
            aria-labelledby="languageDropDown"
          >
            <li>
              <a
                class="dropdown-item d-flex align-items-center"
                href="javascript:void(0);"
                @click="changeLocale('en-GB')"
              >
                <img src="../../../public/images/flags/en-GB.png" alt="" />{{
                  $t("languages.english")
                }}
              </a>
            </li>
            <li>
              <a
                class="dropdown-item d-flex align-items-center"
                href="javascript:void(0);"
                @click="changeLocale('it')"
              >
                <img src="../../../public/images/flags/it.png" alt="" />{{
                  $t("languages.italian")
                }}
              </a>
            </li>
            <li>
              <a
                class="dropdown-item d-flex align-items-center"
                href="javascript:void(0);"
                @click="changeLocale('cn')"
              >
                <img src="../../../public/images/flags/cn.png" alt="" />{{
                  $t("languages.chinese")
                }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import UserService from "../../services/user.service";
import axios from "axios";
import authHeader from "../../services/auth-header";

export default {
  name: "VHeader",
  data() {
    return {
      ps: null,
      cps: null,
      sidebarVisible: false,
    };
  },
  mounted() {
    this.ps = new PerfectScrollbar("#languageDropdown");
  },
  methods: {
    toggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible;
      this.$emit("toggle-sidebar", this.sidebarVisible);
    },
    handleLogout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.replace("/login");
      });
    },
    selectClient(client) {
      UserService.selectClient(client)
        .then(() => {
          this.$store.dispatch("auth/selectClient", client).then(() => {
            this.$router.go(this.$router.currentRoute);
          });
        })
        .catch((error) => {
          // Handle error
        });
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
    },
    goBack() {
      if (this.$router.currentRoute._value.name === "NewIncident") {
        const answer = window.confirm("You have unsaved changes. Are you sure you want to leave?");
        if (!answer) {
          return; 
        }
      }

      // stops the back button from working if there is no past history on the site.
      if (history.state.back === null) {
        return;
      };

      window.history.back();
      let savedPosition = JSON.parse(localStorage.getItem("savedPosition"));
      if (savedPosition) {
        setTimeout(() => {
          window.scrollTo({
            left: savedPosition.left,
            top: savedPosition.top,
            behavior: "smooth",
          });
        }, 400);
      }
      localStorage.removeItem("savedPosition");
    },
    goForward() {
      window.history.forward();
    },
  },
  computed: {
    clients() {
      return this.$store.state.auth.user.clients.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
    },
    currentClient() {
      return this.$store.state.auth.user.clients.find((c) => {
        return (
          parseInt(c.id) ===
          parseInt(this.$store.state.auth.user.selectedClient)
        );
      });
    },
    settings() {
      return this.$router.currentRoute._value.name === "settings";
    },
    night() {
      return this.$store.state.auth.status.dark;
    },
  },
  watch: {
    night(n, o) {
      this.$store.dispatch("auth/changeMode", n);
      let existingCss = document.getElementById("darkThemeLink");
      if (n === true && (existingCss === undefined || existingCss === null)) {
        let file = document.createElement("link");
        file.id = "darkThemeLink";
        file.rel = "stylesheet";
        file.href = "/css/darktheme.css";
        document.head.appendChild(file);
      }
      if (n === false && existingCss !== undefined && existingCss !== null) {
        existingCss.remove();
      }
    },
  },
};
</script>

<style scoped>
.language-dropdown {
  max-height: 50vh;
  overflow-y: scroll;
}
.initials:before {
  background-color: green;
  color: white;
  opacity: 1;
  content: attr(data-initials);
  display: inline-block;
  font-weight: bold;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 0.5em;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
}
.initials {
  display: inline-block;
  width: fit-content;
}
</style>
